import { useContext } from "@nuxtjs/composition-api"

const useStock = () => {
  const { app, $config } = useContext()

  const reAuthorize = async () => {
    const baseUrl = app.$config.baseUrl
    return await app.$axios.get(baseUrl + '/reauthorize-mbc')
    .then(res => {
      const token = res.data.access_token;
      return token
    })
  }

  const getSingleProductStock = async(sku) => {
    let body = JSON.stringify({sku: sku})
    const customerToken = app.$cookies.get("vsf-customer")
    if(!customerToken) return
    const baseUrl = app.$config.baseUrl + '/get-stocks-mbc?type=single&customerToken=' + customerToken
    const catchData = await fetch(baseUrl, {
      method: "POST",
      body,
      headers: {
        "Content-Type": "application/json",
      }
    })

    const response = await catchData.json()

    return response
  }

  const getMultipleProductStocks = async (skus) => {
    let body = JSON.stringify({skus: skus})
    const customerToken = app.$cookies.get("vsf-customer")
    if(!customerToken) return
    const baseUrl = app.$config.baseUrl + '/get-stocks-mbc?type=multiple&customerToken=' + customerToken
    const catchData = await fetch(baseUrl, {
      method: "POST",
      body,
      headers: {
        "Content-Type": "application/json",
      }
    })

    const response = await catchData.json()

    return response
  }

  return {
    getSingleProductStock,
    getMultipleProductStocks
  }
}

export default useStock