export default `
query{
  subBrands {
    attribute_option_id
    cnt
    description
    image
    meta_description
    meta_keywords
    meta_title
    short_description
    small_image
    title
    url
  }
}
`;
