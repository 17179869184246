import { computed, reactive, useContext } from '@nuxtjs/composition-api';
import type {
  UiAlert,
  UseUiAlertInterface,
} from './useUiAlert';

interface alerts {
  alerts: UiAlert[];
}

const state = reactive<alerts>({
  alerts: [],
});
const maxVisibleAlerts = 3;
const timeToLive = 3000;

/**
 * Allows managing and showing alerts to the user.
 *
 * See the {@link UseUialertInterface} for a list of methods and values available in this composable.
 */
export function useUiAlert(): UseUiAlertInterface {
  const { app } = useContext();
  // @ts-ignore
  const cookieMessage = app.$vsf.$magento.config.state.getMessage<Uialert>();

  const sendAlert = (alert: UiAlert) => {
    const id = Symbol('id');

    const dismiss = () => {
      const index = state.alerts.findIndex((n) => n.id === id);

      if (index !== -1) state.alerts.splice(index, 1);

      // @ts-ignore
      app.$vsf.$magento.config.state.removeMessage();
    };

    const newalert = {
      ...alert,
      id,
      dismiss,
    };

    if (!state.alerts.some((stateAlert) => stateAlert.message === alert.message)) {
      state.alerts.push(newalert);
    }

    if (state.alerts.length > maxVisibleAlerts) {
      state.alerts.shift();
    }

    if (!alert.persist) {
      setTimeout(dismiss, timeToLive);
    }
  };

  if (cookieMessage) {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    sendAlert(cookieMessage);
    // @ts-ignore
    app.$vsf.$magento.config.state.removeMessage();
  }

  return {
    sendAlert,
    alerts: computed(() => state.alerts),
  };
}

export default useUiAlert;
export * from './useUiAlert';
